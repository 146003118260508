import React from 'react';
import { motion, Variants } from 'framer-motion';
import { Scale, UserCheck, AlertCircle, Bookmark, Building, Copyright, CreditCard, Truck, Shield, FileText } from 'lucide-react';
import Layout from './components/common/Layout';
import PageHeader from './components/common/PageHeader';

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const TermsOfService: React.FC = () => {
  const sections = [
    {
      icon: <Building className="w-12 h-12 text-eduvibe-green" />,
      title: "Who We Are",
      content: "EduVibz is revolutionizing the education industry by providing an innovative music-based learning platform. We're registered in South Africa as EduVibz (Pty) Ltd (2024/615680/07) and operate from Johannesburg, South Africa."
    },
    {
      icon: <UserCheck className="w-12 h-12 text-eduvibe-green" />,
      title: "User Responsibilities",
      content: "By using EduVibz, you agree to use our services for their intended educational purposes. You're responsible for keeping your account info safe and everything that happens under your account. Think of it like your Instagram password - don't share it! 🔒"
    },
    {
      icon: <Copyright className="w-12 h-12 text-eduvibe-green" />,
      title: "Intellectual Property",
      content: "All our awesome content (music, lyrics, educational materials) is protected by copyright laws. Just like you can't copy your favorite artist's music without permission, you can't reproduce our content without our consent. We've worked hard on these beats! 🎵"
    },
    {
      icon: <CreditCard className="w-12 h-12 text-eduvibe-green" />,
      title: "Subscription & Payments",
      content: "We accept payments via Visa and MasterCard through Paystack - our secure payment partner. All transactions are in South African Rand (ZAR). Subscriptions auto-renew (we'll remind you before charging), and cancellations require one month's notice. Currently, we're only available to South African users."
    },
    {
      icon: <Truck className="w-12 h-12 text-eduvibe-green" />,
      title: "Service Delivery",
      content: "Once we receive your payment, you'll get access to your subscription within 24 hours. You'll receive confirmation via email with your email verification code. No physical shipping needed - it's all digital, fam! 💫"
    },
    {
      icon: <Shield className="w-12 h-12 text-eduvibe-green" />,
      title: "Privacy & Security",
      content: "We take your privacy seriously (as required by POPIA). Your personal info is stored separately from payment details, which are handled securely by Paystack using SSL3 encryption. Check out our full privacy policy at eduvibz.com/privacy-policy. Your data is treated like that secret playlist you don't want anyone to know about! 🛡️"
    },
    {
      icon: <AlertCircle className="w-12 h-12 text-eduvibe-green" />,
      title: "Account Termination",
      content: "We reserve the right to suspend accounts that violate these terms or cause harm to our community. Don't worry though - we're not the fun police, just keep it respectful! ✌️"
    },
    {
      icon: <FileText className="w-12 h-12 text-eduvibe-green" />,
      title: "Changes to Terms",
      content: "We may update these terms occasionally (last updated: 09 November, 2024). We'll notify you of any major changes - no sneaky stuff! Your continued use of EduVibz means you accept any updates."
    }
  ];

  return (
    <Layout showNorthernLights={true}>
      <PageHeader />
      <div className="container mx-auto px-4 py-8">
        <motion.section
          className="max-w-4xl mx-auto mb-16 text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h1 className="text-4xl md:text-5xl font-bold text-eduvibe-green mb-4">
            Terms of Service
          </h1>
          <p className="text-xl text-eduvibe-light">
            Hey there! 👋 Before you dive into the beats, let's cover the basics. These terms govern how we work together to make learning awesome!
          </p>
        </motion.section>

        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {sections.map((section, index) => (
            <motion.div
              key={index}
              className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <div className="flex items-center mb-4">
                {section.icon}
                <h2 className="text-2xl font-bold text-eduvibe-green ml-4">{section.title}</h2>
              </div>
              <p className="text-eduvibe-light">{section.content}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfService;