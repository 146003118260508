import React, { useState } from 'react';
import NorthernLights from './common/NorthernLights';

interface PlanFeature {
  text: string;
  included: boolean;
}

interface PricingPlan {
  name: string;
  monthlyPrice: {
    special: number;
    normal: number;
  };
  annualPrice: {
    special: number;
    normal: number;
  };
  features: PlanFeature[];
}

const Pricing: React.FC = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans: PricingPlan[] = [
    {
      name: "Student Plan",
      monthlyPrice: {
        special: 99,
        normal: 199,
      },
      annualPrice: {
        special: 990,
        normal: 1990,
      },
      features: [
        { text: "Access to all educational songs", included: true },
        { text: "Create custom playlists", included: true },
        { text: "Track progress and achievements", included: true },
        { text: "Gamified learning experience", included: true },
        { text: "Earn badges for completed challenges", included: true },
        { text: "Intuitive and easy-to-use music player", included: true },
      ],
    },
    {
      name: "Family Plan",
      monthlyPrice: {
        special: 249,
        normal: 499,
      },
      annualPrice: {
        special: 2490,
        normal: 4990,
      },
      features: [
        { text: "Access for 2 parents and 2 children", included: true },
        { text: "All Student Plan features for each user", included: true },
        { text: "Parental progress tracking", included: true },
        { text: "Children's listening time monitoring", included: true },
        { text: "Family achievement dashboard", included: true },
        { text: "Shared family playlists", included: true },
        { text: "Parental controls and insights", included: true },
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-eduvibe-dark text-white">
      <div className="relative">
        <NorthernLights />
        <div className="relative z-10 py-16 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-4xl font-bold text-eduvibe-green mb-4">Simple, Transparent Pricing</h2>
              <p className="text-xl text-gray-300 mb-8">Choose the plan that works best for you</p>
              
              {/* Pricing Toggle */}
              <div className="flex items-center justify-center gap-4 mb-12">
                <span className={`text-lg ${!isAnnual ? 'text-eduvibe-green' : 'text-gray-400'}`}>Monthly</span>
                <button
                  onClick={() => setIsAnnual(!isAnnual)}
                  className="relative inline-flex h-6 w-11 items-center rounded-full bg-eduvibe-green"
                >
                  <span
                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition ${
                      isAnnual ? 'translate-x-6' : 'translate-x-1'
                    }`}
                  />
                </button>
                <span className={`text-lg ${isAnnual ? 'text-eduvibe-green' : 'text-gray-400'}`}>
                  Annual (Save 17%)
                </span>
              </div>

              {/* Pricing Cards */}
              <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                {plans.map((plan) => (
                  <div 
                    key={plan.name} 
                    className="bg-eduvibe-blue bg-opacity-50 p-6 rounded-xl backdrop-filter backdrop-blur-lg text-center transform hover:scale-105 transition duration-300"
                  >
                    <div className="text-2xl font-bold text-eduvibe-green mb-4">{plan.name}</div>
                    <div className="mb-8">
                      <div className="flex items-center justify-center gap-4">
                        <div className="text-center">
                          <div className="bg-eduvibe-green/20 rounded-lg px-4 py-2 mb-2">
                            <span className="text-sm text-eduvibe-green font-bold">50% OFF Launch Price</span>
                          </div>
                          <span className="text-4xl font-bold text-white">
                            R{isAnnual ? plan.annualPrice.special : plan.monthlyPrice.special}
                          </span>
                          <div className="text-gray-400 line-through">
                            R{isAnnual ? plan.annualPrice.normal : plan.monthlyPrice.normal}
                          </div>
                          <div className="text-sm text-gray-300">
                            {isAnnual ? '/year' : '/month'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="list-none text-left space-y-3 mb-6">
                      {plan.features.map((feature, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <svg
                            className="w-5 h-5 text-eduvibe-green flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          <span className="text-gray-300">{feature.text}</span>
                        </li>
                      ))}
                    </ul>
                    <button className="bg-eduvibe-green text-eduvibe-dark px-6 py-2 rounded-full text-lg font-bold hover:bg-opacity-90 transition duration-300">
                      Join Waitlist
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
