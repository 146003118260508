import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO: React.FC = () => {
  const siteURL = "https://eduvibz.com";
  const title = "EduVibz - Music-Powered Learning Platform | Learn Through Music";
  const description = "Transform your learning experience with EduVibz. Our innovative platform combines education with music to enhance memory retention. Join thousands of students learning through music. Try our 7-day free trial!";
  const keywords = "education through music, music learning platform, educational songs, memory enhancement, South African education, learning through music, music education, edtech, educational technology";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#2feA9B" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={siteURL} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteURL} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteURL}/og-image.jpg`} />
      <meta property="og:site_name" content="EduVibz" />
      <meta property="og:locale" content="en_ZA" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={siteURL} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteURL}/og-image.jpg`} />
      <meta name="twitter:site" content="@eduvibz" />

      {/* Schema.org markup for Google */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "EduVibz",
          "alternateName": "EduVibz Learning Platform",
          "description": description,
          "url": siteURL,
          "sameAs": [
            "https://www.facebook.com/eduvibz",
            "https://twitter.com/eduvibz",
            "https://www.instagram.com/eduvibz"
          ],
          "potentialAction": {
            "@type": "JoinAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": `${siteURL}/#join`
            },
            "result": {
              "@type": "JoinActionResult",
              "name": "Join Waitlist"
            }
          }
        })}
      </script>

      {/* Organization Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "EduVibz",
          "legalName": "EduVibz (Pty) Ltd",
          "url": siteURL,
          "logo": `${siteURL}/logo.png`,
          "foundingDate": "2024",
          "founders": [{
            "@type": "Person",
            "name": "Jason Viviers"
          }],
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Johannesburg",
            "addressRegion": "Gauteng",
            "addressCountry": "ZA"
          },
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer support",
            "email": "contact@eduvibz.co.za",
            "telephone": "+27832978455"
          }
        })}
      </script>

      {/* Product Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "name": "EduVibz Learning Platform",
          "operatingSystem": "Web",
          "applicationCategory": "EducationalApplication",
          "offers": {
            "@type": "Offer",
            "price": "99.00",
            "priceCurrency": "ZAR",
            "priceValidUntil": "2025-12-31",
            "availability": "PreOrder"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "ratingCount": "150"
          },
          "featureList": [
            "Access to all educational songs",
            "Create custom playlists",
            "Track progress and achievements",
            "Gamified learning experience",
            "Earn badges for completed challenges"
          ]
        })}
      </script>

      {/* FAQ Schema */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "How does the 7-day free trial work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our 7-day free trial gives you full access to all features of your chosen plan. You can cancel anytime during the trial period without being charged. After the trial ends, your selected plan will automatically start unless cancelled."
            }
          }, {
            "@type": "Question",
            "name": "How does the gamified learning experience work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our platform incorporates game-like elements such as points, badges, and achievements to make learning more engaging. Students can earn rewards for completing lessons, mastering topics, and participating in challenges."
            }
          }, {
            "@type": "Question",
            "name": "What payment methods do you accept?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We accept payments via Visa and MasterCard through Paystack, our secure payment partner. All transactions are in South African Rand (ZAR)."
            }
          }]
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
