import React from 'react';
import { Helmet } from 'react-helmet-async';

interface FAQSchemaProps {
  items: Array<{
    question: string;
    answer: string | JSX.Element;
  }>;
}

const FAQSchema: React.FC<FAQSchemaProps> = ({ items }) => {
  // Convert JSX.Element answers to plain text
  const processAnswer = (answer: string | JSX.Element): string => {
    if (typeof answer === 'string') return answer;
    // For JSX elements, we'll extract just the text content
    return answer.props.children instanceof Array
      ? answer.props.children
          .map((child: any) => (typeof child === 'string' ? child : child.props?.children || ''))
          .join(' ')
      : answer.props.children?.toString() || '';
  };

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map(item => ({
      '@type': 'Question',
      name: item.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: processAnswer(item.answer)
      }
    }))
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(faqSchema)}
      </script>
    </Helmet>
  );
};

export default FAQSchema;
