import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';

interface MenuItem {
  path: string;
  label: string;
}

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems: MenuItem[] = [
    { path: '/', label: 'Home' },
    { path: '/how-it-works', label: 'How It Works' },
    { path: '/pricing', label: 'Pricing' },
    { path: '/contact', label: 'Contact' },
    { path: '/faq', label: 'FAQ' },
    { path: '/about-us', label: 'About Us' },
    { path: '/privacy-policy', label: 'Privacy Policy' },
    { path: '/terms-of-service', label: 'Terms of Service' },
    { path: '/cookie-policy', label: 'Cookie Policy' },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-50">
      {/* Mobile menu button - only show when menu is closed */}
      <div className={`fixed top-4 right-4 z-50 ${isOpen ? 'opacity-0' : 'opacity-100'} transition-opacity duration-200`}>
        <button
          onClick={() => setIsOpen(true)}
          className="p-2 rounded-lg bg-eduvibe-dark/80 backdrop-blur-lg text-white hover:bg-eduvibe-blue/20 transition-colors duration-200"
          aria-label="Open menu"
          aria-expanded={isOpen}
        >
          <Menu size={24} />
        </button>
      </div>

      {/* Navigation menu */}
      <div
        className={`fixed inset-0 bg-black/50 transition-opacity duration-200 ${
          isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsOpen(false)}
        aria-hidden="true"
      />
      <div
        className={`fixed inset-y-0 right-0 w-64 bg-eduvibe-dark/95 backdrop-blur-lg shadow-xl transform transition-transform duration-200 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } overflow-y-auto`}
        role="dialog"
        aria-modal="true"
        aria-label="Navigation menu"
      >
        <div className="p-6">
          <div className="flex justify-end">
            <button
              onClick={() => setIsOpen(false)}
              className="p-2 rounded-lg hover:bg-eduvibe-blue/20 transition-colors duration-200"
              aria-label="Close menu"
            >
              <X size={24} className="text-white" />
            </button>
          </div>
          <ul className="space-y-4 mt-8">
            {menuItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className="block py-2 text-white hover:text-eduvibe-green transition-colors duration-200"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
