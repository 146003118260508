import React, { useState } from 'react';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { ChevronDown, Music, Brain, Lock, School, MessageCircle, PenTool, Star } from 'lucide-react';
import { GOOGLE_FORM_URL } from './api/waitlist';
import Layout from './components/common/Layout';
import FAQSchema from './components/common/FAQSchema';
import { Helmet } from 'react-helmet-async';

interface FAQItemProps {
  question: string;
  answer: string | JSX.Element;
  icon: JSX.Element;
}

const fadeIn: Variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const FAQItem: React.FC<FAQItemProps> = ({ question, answer, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4 bg-eduvibe-blue bg-opacity-50 rounded-xl backdrop-filter backdrop-blur-lg transform hover:scale-105 transition duration-300">
      <button
        className="w-full text-left p-6 focus:outline-none flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="flex items-center text-xl font-bold">
          {React.cloneElement(icon, { className: "w-8 h-8 text-eduvibe-green mr-3" })}
          {question}
        </span>
        <ChevronDown
          className={`w-6 h-6 text-eduvibe-green transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="px-6 pb-6"
          >
            <div className="text-white">{answer}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ: React.FC = () => {
  const faqItems: FAQItemProps[] = [
    {
      icon: <Music />,
      question: "What exactly is EduVibz?",
      answer: "EduVibz is an innovative educational platform that harnesses the power of music to enhance learning. We transform curriculum content into catchy, memorable songs, making studying more engaging and effective. It's like having a personal tutor who's also a chart-topping artist!"
    },
    {
      icon: <Brain />,
      question: "How does learning with music actually work?",
      answer: "Great question! Learning with music taps into the brain's natural ability to process and remember patterns. When information is set to music, it engages multiple areas of the brain simultaneously, creating stronger neural connections. This can lead to improved recall and understanding. Plus, it's just more fun! Check out our 'How It Works' page for more scientific details."
    },
    {
      icon: <School />,
      question: "Is EduVibz suitable for all ages and subjects?",
      answer: "Absolutely! Our list may be small now, but it is ever growing. Have a look at the curriculae, grades and subjects covered. Feel free to request yours if it is not there in the feature request tab on our contact page. EduVibz is designed to cater to learners of all ages, from primary school to university level. Our AI-powered system can adapt content for any subject, whether it's mathematics, history, languages, or sciences. The tunes might change, but the effectiveness remains!"
    },
    {
      icon: <Lock />,
      question: "How does EduVibz protect my privacy?",
      answer: (
        <div>
          <p>We take your privacy seriously at EduVibz. All personal data is encrypted and stored securely. We never share your information with third parties without your explicit consent.</p>
          <p className="mt-2">For more details, please review our <a href="/privacy-policy" className="text-eduvibe-green hover:underline">Privacy Policy</a>.</p>
        </div>
      )
    },
    {
      icon: <MessageCircle />,
      question: "How can I get in touch with EduVibz?",
      answer: (
        <div>
          <p>We'd love to hear from you! Here's how you can reach us:</p>
          <ul className="list-disc list-inside mt-2">
            <li>For general inquiries: Visit our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
            <li>For schools wanting to join the waitlist: Use the 'Waitlist' form on our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
            <li>To apply as a music contributor: Fill out the 'Contribute' form on our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
            <li>To request features or songs: Use the 'Feature' form on our <a href="/contact" className="text-eduvibe-green hover:underline">Contact Page</a></li>
          </ul>
        </div>
      )
    },
    {
      icon: <PenTool />,
      question: "Can I contribute my own educational songs to EduVibz?",
      answer: "Absolutely! We're always looking for talented individuals to contribute to our musical education revolution. If you're interested in creating educational content for EduVibz, please fill out the 'Contribute' form on our Contact Page. Note that we have a revenue share agreement for accepted contributions."
    },
    {
      icon: <Star />,
      question: "Can I request specific songs or features?",
      answer: "Of course! We love hearing from our users. You can submit feature or song requests through the 'Feature Request' form on our Contact Page. While we can't guarantee that all requests will be implemented, we seriously consider all user feedback. Please note that no royalties are payable for feature requests."
    },
    {
      icon: <School />,
      question: "I'm a teacher/school administrator. How can we use EduVibz in our curriculum?",
      answer: "We're excited to work with educators! EduVibz is working on a solution that can be integrated into your curriculum in various ways, from custom quizzes to monitoring and achievements. If you're interested in bringing EduVibz to your school, please fill out the 'Waitlist' form on our Contact Page. We'll get in touch to discuss how we can best serve your specific educational needs."
    },
    {
      icon: <Lock />,
      question: "What are the terms of service for using EduVibz?",
      answer: (
        <div>
          <p>Our terms of service outline the rules and regulations for using the EduVibz platform. This includes information about user accounts, content usage, and our policies on data protection.</p>
          <p className="mt-2">For the full details, please read our <a href="/terms-of-service" className="text-eduvibe-green hover:underline">Terms of Service</a>.</p>
        </div>
      )
    },
    {
      icon: <Brain />,
      question: "Is EduVibz backed by scientific research?",
      answer: "Yes, indeed! The concept behind EduVibz is grounded in extensive scientific research on music and learning. Studies have shown that music can enhance memory formation, improve focus, and make learning more enjoyable. We're constantly updating our methods based on the latest research in neuroscience and education. Check out our 'How It Works' page for more details and references to specific studies."
    },
    {
      icon: <Music />,
      question: "What kind of music does EduVibz use?",
      answer: "Our vision is for EduVibz to use a wide variety of musical styles to cater to different tastes and learning preferences. Our system can create educational songs in genres ranging from pop and rock to classical and electronic. The list is continuously growing, so you will keep seeing even more value for your subscription. The goal is to find the perfect beat for every learner!"
    }
  ];

  return (
    <Layout showNorthernLights={true}>
      <Helmet>
        <title>Frequently Asked Questions | EduVibz - Music-Powered Learning Platform</title>
        <meta name="description" content="Get answers to common questions about EduVibz's music-powered learning platform. Learn how we use music to enhance education, our privacy policy, and how to get started." />
        <meta name="keywords" content="EduVibz FAQ, music learning FAQ, educational platform questions, learning with music, educational songs, South African education" />
        <link rel="canonical" href="https://eduvibz.com/faq" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="FAQ | EduVibz - Music-Powered Learning Platform" />
        <meta property="og:description" content="Get answers to common questions about EduVibz's music-powered learning platform. Learn how we use music to enhance education." />
        <meta property="og:url" content="https://eduvibz.com/faq" />
        
        {/* Twitter */}
        <meta name="twitter:title" content="FAQ | EduVibz - Music-Powered Learning Platform" />
        <meta name="twitter:description" content="Get answers to common questions about EduVibz's music-powered learning platform. Learn how we use music to enhance education." />
      </Helmet>

      <FAQSchema items={faqItems} />

      <div className="container mx-auto px-4 py-8">
        <motion.div
          className="max-w-4xl mx-auto mb-16 text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h1 className="text-6xl md:text-7xl font-extrabold text-center text-eduvibe-green mb-8">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-eduvibe-light mb-12">
            Find answers to common questions about EduVibz's music-powered learning platform. Can't find what you're looking for? <a href="/contact" className="text-eduvibe-green hover:underline">Contact us</a>.
          </p>
        </motion.div>

        <nav aria-label="FAQ Categories" className="max-w-4xl mx-auto mb-12">
          <ul className="flex flex-wrap justify-center gap-4">
            {['Platform Basics', 'Learning Process', 'Privacy & Security', 'Pricing & Plans', 'Support'].map((category) => (
              <li key={category}>
                <button 
                  className="px-6 py-2 rounded-full bg-eduvibe-blue bg-opacity-50 hover:bg-opacity-70 transition-all"
                  onClick={() => {/* Implement category filtering if needed */}}
                >
                  {category}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        <motion.section
          className="max-w-4xl mx-auto mb-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <div className="space-y-4" itemScope itemType="https://schema.org/FAQPage">
            {faqItems.map((item, index) => (
              <div key={index} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <FAQItem
                  question={item.question}
                  answer={item.answer}
                  icon={item.icon}
                />
              </div>
            ))}
          </div>
        </motion.section>

        <motion.section
          className="max-w-4xl mx-auto text-center"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
        >
          <h2 className="text-4xl font-bold mb-8 text-eduvibe-green">Still Have Questions?</h2>
          <p className="text-xl mb-8">
            Join our waitlist to stay updated and get answers to all your questions.
          </p>
          <a
            href={GOOGLE_FORM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-eduvibe-green to-eduvibe-blue text-white px-8 py-4 rounded-lg text-xl font-bold hover:opacity-90 transition duration-300"
          >
            Join Waitlist
          </a>
        </motion.section>
      </div>
    </Layout>
  );
};

export default FAQ;