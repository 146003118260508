import React from 'react';

const Contact: React.FC = () => {
  return (
    <div className="bg-eduvibe-dark py-16 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-eduvibe-green mb-4">Contact Us</h2>
          <p className="text-xl text-gray-300">We're here to help!</p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-2xl backdrop-blur-lg">
            <h3 className="text-2xl font-bold text-eduvibe-green mb-6">Get in Touch</h3>
            <div className="space-y-4">
              <div className="flex items-center gap-4">
                <svg className="w-6 h-6 text-eduvibe-green" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <a href="mailto:contact@eduvibz.co.za" className="text-gray-300 hover:text-eduvibe-green transition duration-300">
                  contact@eduvibz.co.za
                </a>
              </div>
              <div className="flex items-center gap-4">
                <svg className="w-6 h-6 text-eduvibe-green" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <a href="tel:0832978455" className="text-gray-300 hover:text-eduvibe-green transition duration-300">
                  083 297 8455
                </a>
              </div>
            </div>
          </div>
          
          <div className="bg-eduvibe-blue bg-opacity-50 p-8 rounded-2xl backdrop-blur-lg">
            <h3 className="text-2xl font-bold text-eduvibe-green mb-6">Office Hours</h3>
            <div className="space-y-4 text-gray-300">
              <p>Monday - Friday: 8:00 AM - 5:00 PM</p>
              <p>Saturday: 9:00 AM - 1:00 PM</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
